/* old style for user start*/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  /* width: 400px; */
  max-width: 100%;
  max-width: auto;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.form-actions button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-actions button[type="submit"] {
  background-color: #4CAF50;
  color: white;
}

.form-actions button[type="button"] {
  background-color: #f44336;
  color: white;
}

.user_id {
  margin-right: auto;
}

/* old style for user end */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.manage_dealer_form.waba_form .modal-content {
  background: #fff;
  padding: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  /* width: 700px;
  max-width: 100%; */
}

.maxW700 {
  border-radius: 8px;
  background: #fff;
  width: 100%;
  max-width: 700px;
  margin: auto;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-actions {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
}

.form-actions button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-actions button[type="submit"] {
  background-color: #00acc1;
  color: white;
}

.form-actions button[type="submit"]:hover {
  background-color: #037585;
}

.form-actions button[type="button"] {
  background-color: #dc3545;
  color: white;
}

.form-actions button[type="button"]:hover {
  background-color: #9e0c1b;
}

.user_id {
  margin-right: auto;
  position: absolute;
  left: 10px;
}

/* .manage_dealer_form.waba_form .modal-header {
  display: initial;
} */

.manage_dealer_form.waba_form .modal-header .modal-title {
  text-align: center;
}

.form-group {
  display: flex;
  align-items: center;
  flex: 1;
}

.form-group.bussiness_num {
  max-width: 49%;
}

.form-label {
  margin-right: 10px;
}

.input-field {
  flex: 1;
  margin-right: 10px;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.check-button {
  padding: 8px 12px;
  background-color: #00acc1;
  color: white;
  border: none;
  /* border-radius: 4px; */
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.check-button:hover {
  background-color: #038091;
}

.error-msg {
  color: red;
  margin-left: 2px;
  font-size: 0.9em;
  position: absolute;
  bottom: -23px;

}

button.check-button {
  position: absolute;
  right: 0;
  top: 32px;
}

.manage_dealer_form .modal-header {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 10px;
  /* margin-bottom: 10px; */
}

p.fill_note {
  font-weight: bold;
  color: #054c97;
  margin-bottom: 10px;
}

.form-actions.button_btn_submit {
  /* margin-top: 10px; */
  justify-content: flex-start;
}

@media(max-width:1075px) {
  .AdminSearchContain .user_id {
    position: static;
    margin-right: 10px;
  }
}

@media (max-width: 992px) {

  .form-group.bussiness_num {
    max-width: 100%;
  }
}

@media(max-width:565px) {
  .AdminSearchContain:has(> .user_id) {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
  }

  .AdminSearchContain:has(> .user_id) .Session_report_SearchContain {
    position: static;
  }

  .AdminSearchContain:has(> .user_id) .add_btn6 {
    position: static;
  }
}

@media(max-width:475px) {
  .AdminSearchContain:has(> .user_id) {
    justify-content: center;
  }
}


.AdminSearchContain.text_capitalize {
  text-transform: capitalize;
}

/* new g */

.parent-modal,
.modal_box_new {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1400;
}


.parent-modal__overlay,
.modal_box_new__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;

}

.parent-modal__content,
.modal_box_new__content {
  background-color: white;

  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  position: relative;
  z-index: 9999999;
  /* margin-top: 130px; */
}

.modal_box_new__content {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.parent-modal h2,
.heading_text_ne {
  margin-top: 0;
  font-size: 24px;
  text-align: center;
}

.parent-modal__form-container,
.form-container1 {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.parent-modal__form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.parent-modal__actions {
  padding: 20px;
}

.modal_header_t {
  border-top-left-radius: 6px;
  border-top-right-radius: 5px;
  background-color: gray;
  padding: 6px;
  font-size: 15px;
  color: #fff;
}

.parent-modal__form-group {
  flex: 1;
}

.parent-modal__form-group input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.parent-modal__actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 20px;
}

.parent-modal__actions.modal__actions_new {
  margin-top: 0;
  padding-top: 0;
}

.parent-modal__save-btn,
.parent-modal__close-btn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.parent-modal__save-btn {
  background-color: #00acc1;
  color: white;
}

.parent-modal__close-btn {
  background-color: #dc3545;
  color: white;
}


.parent-modal__save-btn:hover {
  background-color: #00abc1c7;
}

.parent-modal__close-btn:hover {
  background-color: rgba(229, 56, 53, 0.795);
}

@media(max-width: 490px) {
  .parent-modal__form-row {
    display: block;
  }

  .parent-modal__form-container {
    height: 330px;
    overflow: auto;
  }
}
.Session_report_SearchContain.search_btn_in{
  display: flex;
  align-items: center;
}
