.Template_id_contian1{ 
    background-color: #fff;
    border-radius: 10px;
}
.Head_titleTemplate{ 
    text-align: center;
    padding: 10px 0;
    font-size: 22px;
    background-color: gray;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
}
.Template_id_Card1{
    padding-top: 20px;
}

.Table{
    margin: 0;
}
.Table thead th,.Table tbody td{
    border-top: none;
    border-left: none;
    border-right: 1px solid #bbbaba;
    border-bottom: 1px solid #bbbaba;
    text-align: center;
    padding: 7px;
}
.Table thead th:first-child,.Table td:first-child{
    border-left: 1px solid #bbbaba;
}
.Table thead tr th{
    border-top: 1px solid #bbbaba;
}
.Table thead th{
    background-color: #e1dede;
    font-size: 13px;
    vertical-align: middle;
    padding: 10px;
}
.Table thead th span{
    font-size: 13px;
}
.Table td{
    font-size: 12px;
    font-weight: 400;
    background-color:#fbf0ef;
    vertical-align: middle;
}
.Table td p{
    width: 250px;
    font-size: 13px;
    font-weight: 400;
}
.Table td input{
    width: 100%;
    border: 1px solid #cbc8c8;
    padding: 7px;
    border-radius: 5px;
}
.Table td button{
    border: none;
    /* background-color: #343a40; */
    background-color: #f90000;
    color: #fff;
    padding: 3px 7px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    margin-top: 5px;
}
.Table td button.status_deactive,.Table td.status_deactive{
    color: #f90000;
}
.Table td button.status_active,.Table td.status_active{
    color: green;
}
.Table td button:hover{
    background-color: #161616;
}
.Table td button.download_icon{
    background-color: #00acc1;
}
.Table td button img{
    width: 16px;
    filter: invert();
}
.add_btn1{
    border: none;
    background-color: #343a40;
    font-size: 14px;
    /* width: 85px; */
    color: #fff;
    padding: 7px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    position: absolute;
    right: 7px;
    top: 7px;
    cursor: pointer;
}
.add_btn1 img{
    width: 18px;
    filter: invert();
    margin-right: 5px;
}
.dt-layout-full:has(){
    max-height: 400px;
    overflow: auto;
    padding: 0;
}
.row.dt-layout-table{
    margin: 0;  
}
.dt-info{
    font-weight: 400;
}

.modal-title{
    color: #fff;
}
.modal-header{
    background-color: gray;
}
.btn-close{
    filter: invert();
    opacity: 1;
}
.Modal_Table_contain{
    max-height: 375px;
    overflow: auto;
}
.Modal_Table_contain h5{
    color: #f90000;
    text-align: center;
}
.Modal_Table_contain p{
    font-weight: 400;
}


.WMadd_btntemp1.add_btn1{
    width: 130px;
}

label.buttonText_1111{
    margin-bottom: 0px !important;
    margin-right: 30px;
    width: 63px;
}

.viewCount{
    margin-top: 24px;
}

.actionBtn{
    width: 40px !important;
}

li.css-1t6ue01-MuiListSubheader-root{
    position: static;
}

div#tableContain{
    max-height: 500px;
    width: 100%;
    overflow-x: auto;
    padding: 0 10px 10px;
}
.pending{
    color: yellow;
    font-size: 13px;
}
.approved{
    color: green;
    font-size: 13px;
}
.rejected{
    color: #f90000;
    font-size: 13px;
}
@media(max-width:768px){
    #Table_wrapper .dt-layout-start,#Table_wrapper .dt-layout-end{
        width: fit-content;
    }
}
@media(max-width:498px){
    .Head_titleTemplate{
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
    }
    
}
@media(max-width:375px){
    .Head_titleTemplate .add_btn1 {
        position: static;
        margin-left: 5px;
        margin-top: 5px;
        display: inline-block;
    }
    .Head_titleTemplate{
        text-align: center;
    }
}