.ReportDetails{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}
.ReportDetails.new_ReportDetails{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: space-between; */
    padding: 10px;
}
.Font14,.Head_title span.Font14{
font-size: 14px;}
.ReportDetails .Session_report_SearchContain{
    position: static;
}
.DeliveryReapotTable thead th{
    text-transform: capitalize;
}
.DeliveryReapotTable tbody tr td:nth-last-child(1),
.DeliveryReapotTable tbody tr td:nth-last-child(2) {
    text-transform:capitalize;
}

.Template_id_contian .Table tr td.downloadIcon button{
    background-color: #00acc1;
}
.Head_title.ReportDetails.new_ReportDetails span{
    margin: auto;
}
.Head_title.ReportDetails .DateRangeContain.new_DateRangeContain{
    position: absolute;
    left: 10px;
}
@media(max-width: 600px){
    .Head_title.ReportDetails .DateRangeContain.new_DateRangeContain{
        position: initial;
    }  
}