/* ChangePassword.css */
.change-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 70vh; */
    background-color: #f5f5f5;
}
.change-password-form-container{
    width: 100%;
    max-width: 400px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}
.change-password-form {
    width: 100%;
    padding: 20px;
    text-align: center;
}

.change-password-form .form-group {
    margin-bottom: 15px;
    text-align: left;
    display: block;
}
 
.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.error {
    color: red;
    font-size: 0.875em;
    display: block;
    margin-top: 5px;
}

.change_pass_button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.change_pass_button:hover {
    background-color: #0056b3;
}
