.DateRangeContain .react-calendar__navigation{
    background-color: gray;
}
.DateRangeContain .react-calendar__navigation button{
    color: #fff;
}
.ResetButton{
    background-color: #fff;
    border: 1px solid gray;
    border-top: none;
    padding: 10px;
    font-size: 13px;
    display: block;
    width: 100%;
    transition: .4s;
}
.ResetButton:hover{
background-color: gray;
color: #fff;
}
