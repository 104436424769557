.Creattebot {
    width: 100%;
    height: 100vh;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* createbot.css */
  /* Debugging Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal1_f {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
  
 
  
  .create_bot_btn {
    border: none;
    background-color: #343a40;
    font-size: 14px;
    color: #fff;
    padding: 7px 10px 7px 4px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    float: right;
    cursor: pointer;
    margin-top: -20px;
    margin-bottom: 20px;
  }
  
  .create_bot_btn img {
    width: 18px;
    filter: invert();
  }
  
  .Modal_save_btn {
    background-color: #343a40;
  }
  
  .Modal_cancel_btn {
    background-color: red;
  }
  
  .Modal_save_btn,
  .Modal_cancel_btn {
    font-size: 13px;
  
    color: #fff;
    width: 85px;
    border: none;
    padding: 7px 0;
    border-radius: 5px;
  }
  
  .modal_input_bot_f {
    outline: none;
    border: 1px solid #ddd;
    width: 100%;
    height: 100px;
  }
  
  .modal_input_bot:focus,
  .modal_input_bot:hover {
    border: 1px solid #343a40;
  }
  
  .modal1_f h5 {
    font-size: 16px;
  }
  
  .botf_modal_container {
    flex-direction: column;
  }
  
  .bot_modal_close_btn_f {
    margin-left: 433px;
  }