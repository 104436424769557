.Mis_report_contian{ 
    background-color: #fff;
    border-radius: 10px;
}
.Head_title{ 
    text-align: center;
    padding: 10px 0;
    font-size: 22px;
    background-color: gray;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
}
.Mis_report_Card{
    padding-top: 20px;
}
.Date_contain{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    padding-bottom: 20px ;
}
.form_group333{
    margin-right: 20px;
}
.form_group333:last-child{
    margin: 0;
}
.form_group333 label{
    display: block;
    /* color: #d61616;; */
}
.form_group333 input{
    font-size: 14px;
    padding: 7px;
    border: 1px solid #bbbaba;
    border-radius: 5px;
}
.form_group333 button{
    border-radius: 5px;
    border: none;
    background-color: #343a40;
    font-size: 14px;
    /* width: 85px; */
    color: #fff;
    padding: 8px 14px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    display: flex;
    align-items: center;
}
.form_group333 button.export_btn{
    background-color: green;
}
.form_group333 button img{
    filter: invert();
    width: 16px;
    margin-right: 10px;
}
.table_contain{
    width: 100%;
    overflow-x: auto;
}
.Table{
    margin: 0;
}
.Table tr th,.Table tr td{
    border-top: none;
    border-left: none;
    border-right: 1px solid #bbbaba;
    border-bottom: 1px solid #bbbaba;
    text-align: center;
    padding: 7px;
}
.Table tr th:first-child,.Table tr td:first-child{
    border-left: 1px solid #bbbaba;
}
.Table  tr th{
    border-top: 1px solid #bbbaba;
    text-wrap: nowrap;
}
.Table tr th{
    background-color: #e1dede;
    font-size: 13px;
    vertical-align: middle;
    padding: 10px;
}
.Table tr td{
    font-size: 12px;
    font-weight: 400;
    background-color:#fff;
    vertical-align: middle;
}
.Table tfoot tr td{
    background-color:#fbf0ef;
}
.Table tr td input{
    width: 100%;
    border: 1px solid #cbc8c8;
    padding: 7px;
    border-radius: 5px;
}
.Table tr td button{
    border: none;
    /* background-color: #343a40; */
    background-color: #f90000;
    color: #fff;
    padding: 3px 7px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    margin-top: 5px;
}
.Table td button.status_deactive,.Table td.status_deactive{
    color: #f90000;
}
.Table td button.status_active,.Table td.status_active{
    color: green;
}
.Table td button.download_icon,.Table td.download_icon button{
    background-color: #00acc1;
}
/* .Table td button:hover{
    background-color: #161616;

} */
.Table td button img{
    width: 13px;
    filter: invert();
}
.add_btn{
    border: none;
    background-color: #343a40;
    font-size: 14px;
    width: 85px;
    color: #fff;
    padding: 7px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    position: absolute;
    right: 7px;
    top: 7px;
}
.add_btn img{
    width: 18px;
    filter: invert();
    margin-right: 5px;
}
.dt-layout-full:has(){
    max-height: 400px;
    overflow: auto;
    padding: 0;
}
.row.dt-layout-table{
    margin: 0;  
}
.dt-info{
    font-weight: 400;
}