.Template_id_contian2{ 
    background-color: #fff;
    border-radius: 10px;
}
.Head_title2{ 
    text-align: center;
    padding: 10px 0;
    font-size: 22px;
    background-color: gray;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
}
.Template_id_Card2{
    padding-top: 20px;
    padding-bottom: 10px;
}

.Table{
    margin: 0;
}
.Table thead th,.Table tbody td{
    border-top: none;
    border-left: none;
    border-right: 1px solid #bbbaba;
    border-bottom: 1px solid #bbbaba;
    text-align: center;
    padding: 7px;
}
.Table thead th:first-child,.Table td:first-child{
    border-left: 1px solid #bbbaba;
}
.Table thead tr th{
    border-top: 1px solid #bbbaba;
}
.Table thead th{
    background-color: #e1dede;
    font-size: 13px;
    vertical-align: middle;
    padding: 10px;
}
.Table thead th span{
    font-size: 13px;
}
.Table td{
    font-size: 12px;
    font-weight: 400;
    background-color:#fbf0ef;
    vertical-align: middle;
}
.Table td p{
    width: 250px;
    font-size: 13px;
    font-weight: 400;
}
.Table td input{
    width: 100%;
    border: 1px solid #cbc8c8;
    padding: 7px;
    border-radius: 5px;
}
.Table td button,.Table td a{
    border: none;
    /* background-color: #343a40; */
    background-color: #f90000;
    color: #fff;
    padding: 4px 5px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    margin-top: 5px;
}
.Table td a:has( > button) {
    margin: 0;
    padding: 0;
    box-shadow: none;
    background: transparent; 
}
.Table td a button{
    height: 100%;
}
.Table td button.status_deactive,.Table td.status_deactive{
    color: #f90000;
}
.Table td button.status_active,.Table td.status_active{
    color: green;
}
.Table td button:hover{
    background-color: #161616;
}
.Table td button.download_icon,.Table td .download_icon{
    background-color: #00acc1;
}
.Table td button.copy_icon{
    background-color: green;
}
.Table td button img,.Table td a img{
    width: 16px;
    filter: invert();
}
.MangeMediaUrlTextContian{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.MangeMediaUrlText{
    min-width: 150px;
    font-weight: 400;
    font-size: 12px;
    width: 100%;
}
.add_btn2{
    border: none;
    background-color: #343a40;
    font-size: 14px;
    /* width: 85px; */
    color: #fff;
    padding: 7px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    position: absolute;
    right: 7px;
    top: 7px;
    cursor: pointer;
}
.WMadd_btn.add_btn2{
    width: 112px;
}
.add_btn2 img{
    width: 18px;
    filter: invert();
    margin-right: 5px;
}
.dt-layout-full:has(){
    max-height: 400px;
    overflow: auto;
    padding: 0;
}
.row.dt-layout-table{
    margin: 0;  
}
.dt-info{
    font-weight: 400;
}
/* modal style start */
#Add_btn_modal{
    padding: 0;
}
.Modal_table{
    width: 100%;
}
.Modal_table td{
    font-size: 13px;
    padding: 10px;
    border: 1px solid #dee2e6;
    background-color: #E6F5EB;
    text-align: center;
    vertical-align: middle;
}
.Modal_table td input,.Modal_table td textarea,.Modal_table td select{
    font-size: 13px;
    width: 100%;
    padding: 7px;
    border: 1px solid #dee2e6;
    font-weight: 400;
    border-radius: 5px;
}
.Modal_table td input:focus-visible,.Modal_table td textarea:focus-visible,.Modal_table td select:focus-visible{
    outline: none;
}
.Modal_table td input::placeholder{
    font-weight: 400;
}
.Modal_table td button{
    font-size: 13px;
    background-color: #343a40;
    color: #fff;
    width: 85px;
    border: none;
    padding: 7px 0;
    border-radius: 5px;
}
.required_icon{
    font-size: 12px;
    color: #f90000;
}
.modal-title{
    color: #fff;
}
.modal-header{
    background-color: gray;
}
.btn-close{
    filter: invert();
    opacity: 1;
}
.Modal_Table_contain{
    max-height: 375px;
    overflow: auto;
}
.maxW400,.maxW400.modal-dialog{
    max-width: 400px;
}
.maxW500{
    max-width: 400px;
}
.Table td .DeleteConfirmModalContain{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #00000093;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Table td .DeleteConfirmModal{
    max-width: 400px;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.Table td .DeleteConfirmModal p{
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    width: 100%;
}
.Table td .DeleteConfirmModal .modal-buttons{
    justify-content: center;
    gap: 20px;
}
@media(max-width:768px){
    #Table_wrapper .dt-layout-start,#Table_wrapper .dt-layout-end{
        width: fit-content;
    }
}
@media(max-width:427px){
    .Head_title2{
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
    }
    
}
@media(max-width:320px){
    .add_btn2 {
        position: static;
        margin-top: 5px;
    }
    .Head_title2{
        text-align: center;
    }
}