.WB_add_template_contain{
    background-color: #fff;
    border-radius: 10px;
}
.Head_title{
    text-align: center;
    padding: 10px 0;
    font-size: 22px;
    background-color: gray;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* margin-bottom: 20px; */
}
.Customize_card{
    padding-top: 20px;
}
.Customize_card > p{
    font-size: 16px;
    margin-bottom: 10px;
}
.custm_docs{
    border: 1px solid grey;
    padding: 20px;
    text-align: center;
    background-color: #dfdddd;
    background-color: #ededed;
    border-radius: 10px;
    /* box-shadow: rgba(100; 100; 111, 0.2) 0px 7px 29px 0px; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 10px;
}

.custm_docs p{
    margin-bottom: 10px;
}
.custm_docs h4{
    font-size: 18px;
    font-weight: 600;
    color: rgb(14, 13, 78);
    margin-bottom: 10px;
}
.custm_docs button{
    border: none;
    background-color: #343a40;
    color: #fff;
    padding: 7px 15px;
    border-radius: 5px;
}
.form_group{
    margin-top: 10px;
}
.form_group label,label{
    font-weight: 500;
    margin-bottom: 5px;
}
.form_group input,.form_group select{
    width: 100%;
    border: 1px solid #b7b5b5;
    font-weight: 400;
    height: 42px;
    padding: 10px;
}
.form_group input.p_7{
    padding: 7px;
}
.form_group select::after,.form_group select::before{
    background: none;
    content: '';
}
.form_group select.h_85{
    height: 85px;
    -webkit-appearance: none; 
    appearance: none; 
    overflow: scroll;
}
option{
    font-weight: 400;
}
.form_group textarea{
    border: 1px solid #b7b5b5;
    padding: 10px;
    width: 100%;
}
.form_group textarea:focus-visible,.form_group select:focus-visible{
    outline: none;
}
.form_group button{
    border: none;
    background-color: #343a40;
    color: #fff;
    padding: 7px 0;
    width: 130px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    margin: 0 5px;
}
.form_group button:hover{
    background-color: #161616;

}
.form_group button.save{
    background-color: #00acc1;
}
.form_group button.cancel{
    background-color: red;
}
.form_group button.actionBtn{
    padding: 10px 0;
}
.WB_template_header{
    padding: 5px 0;
    margin-top: 12px;
}
.WB_template_header p{
    font-weight: 400;
}
.message_icons{
    margin-left: 5px;
}
.message_icons button{
    width: 30px;
    height: 35px;
    margin: 0 5px;
    margin-bottom: 10px;
}
.message_icons img{
    filter: invert();
    width: 14px;
}
.message_icons button:first-child img{
    width: 20px;
}
.List_card{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}.List_card span{
    font-size: 11px;
    margin: 0;
}

/* whatsApp template message container */
.message-container {
    display: flex;
    justify-content: flex-start; 
    position: relative; 
    margin: 20px;
}
  
.preview-box {
    background-color: #122E31;
    color: white;
    border-radius: 10px;
    padding: 10px 15px;
    position: relative;
    max-width: 300px;
}

.preview-box:hover .tooltip {
    opacity: 1;
}

.template_header {
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    padding: 10px;
    max-width: 320px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    margin-top: 10px;
    position: relative;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.uploaded-image, .uploaded-video, .uploaded-document {
    border-radius: 10px;
    max-width: 100%;
    max-height: 200px;
}

.form-inline {
    display: flex;
    align-items: center;
}

.custom-select-input, .custom-input {
    border: 1px solid #ced4da;
    padding: 0.5rem; 
    margin-right: 1rem; 
}

.custom-select-input {
    width: 33% !important;
}

button.react-international-phone-country-selector-button{
    background-color: #dddddd8a;
    width: 50px;
    height: 42px;
    box-shadow: none;
}

button.react-international-phone-country-selector-button:hover{
    background-color: #dddddd;
}

.custom-input {
  border: 1px solid #ced4da;
}

.input-label {
  font-size: 0.9rem;
  position: relative;
  top: 32px;
  right: 26px;
  color: #555;
}
.showInputCount{
    position: relative;
}

.showInputCount span{
    position: absolute;
    right: 0;
    margin-bottom: 10px;
    top: 40px;
}

.react-international-phone-input-container{
    /* margin-left: 10px; */
}

.react-international-phone-input-container input.react-international-phone-input{
    height: 42px;
}

#visitWebsiteDomain{
    margin-left: 15px;
    width: 33% !important;
}

input.width__40, div.width__40{
    width: 330px;
}

.preview-box1 {
    background-color: #122E31;
    color: white;
    border-radius: 10px;
    padding: 10px 15px;
    position: relative;
    max-width: 300px;
    margin-right: 10px;
}

.customInp{
    width: 100%;
    border: 1px solid #b7b5b5;
    font-weight: 400;
    height: 42px;
    padding: 10px;
    margin-top: 10px;
}

div.mt-55{
    margin-top: 38px ;
}

button:disabled{
    background-color: #ebebe495 ;
    color: rgba(0, 0, 0, 0.473);
}

button:disabled:hover{
    background-color: #ebebe476;
}

div.epr_i3a6gx{
    background: transparent;
}

div#epr-category-nav-id button{
    background-color: transparent;
    height: 30px;
}

.emoji-picker-container{
   width: fit-content;
}

div.MuiButtonGroup-root{
    box-shadow: none;
}

li.MuiListSubheader-root{
    font-size: 15px;
    color: black;
}

hr.MuiDivider-root{
    background-color: black;
    border-width: 1;
}

input.inputQuickReply{
    width: 415px !important;
}


/* preview contain  style start*/
.Preview_Contain {
    position: relative;
    background-image: url(/src/Assets/images/mobile_png2.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 450px;
    padding-top: 45px;
    padding-left: 20px; 
    padding-right: 20px; 
    padding-bottom: 20px; 
    box-sizing: border-box;
}



/* .Preview_Contain img{
    height: 500px;
} */
.Preview_card{
    max-width: 255px;
    width: 100%;
    height: 335px;
    margin: auto;
    overflow: auto;
    scrollbar-width: none;
    padding: 0 7px;
}
.Preview_head{
    max-width: 255px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px;
    background-color: rgb(20, 100, 40);
    color: #fff;
    position: relative;
    text-align: center;
}
.Preview_user{
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}
.Preview_user img{
    width: 14px;
}
.Preview_title{
    width: 100%;
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin: 0;
}
/* preview contain style end */

.mainBox{
    background-color: #fff;
    max-height: auto;
    padding: 8px;
    border-radius: 5px;
}

.showMedia,.showVideo,.showImages,.showLocation,.showCarousel{
    height: 100px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    background-color: #ece5dd;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MediaIcon{
    max-width: 125px;
    max-height: 90px;
    /* height: 100%; */
}
  
.viewMedia{
    width: 100%;
    height: 100%;
    object-fit: "cover"
}

.uploaded-document{
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.templateHeader{
    font-weight: bold;
    white-space: pre-wrap;
    word-wrap: break-word;
}
.templateMessage p{
    /* max-height: 100px;
    overflow-y: auto; */
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 5px;
    color: #4c5258;
    font-size: 14px;
}
.templateFooter{
    color: #9d9d9d;
    font-weight: 500;
    font-size: 12px;
    white-space: pre-wrap;
    word-wrap: break-word;
}
button.commonBtn, a.commonBtn{
    font-size: 12px;
    color: blue;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0.25rem 6px rgb(50 50 93 / 5%), 0 1px 3px rgb(0 0 0 / 5%);
    transition: all 0.2s ease-out;
    min-width: 118px;
    width: fit-content;
    text-wrap: nowrap;
    
}
button.commonBtn:hover, a.commonBtn:hover{
    background-color: #fff;
}

.PreviewBtnContain{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: start;
}

input#Document, input#Images, input#Video,.radio-inline input{
    height: 15px;
    display: inline-block;
    width: fit-content;
    margin-right: 5px;
}

.radio-inline{
    display: flex;
    align-items: center;
}
.FooterTitle{
    display: inline-block;
    margin-bottom: 0;
}
.FooterTitle:hover{
    cursor: pointer;
}
.FooterHintContent{
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: .4s;
}
.FooterTitle:hover + .FooterHintContent{
    visibility: visible;
    opacity: 1;
    height: auto;
    margin-top: 5px;
}
.AddPhoneNumberContain.react-international-phone-input-container .react-international-phone-input,.AddPhoneNumberContain.react-international-phone-input-container .react-international-phone-country-selector-button{ 
    border-radius: 0;
}
.AddPhoneNumberContain.react-international-phone-input-container .react-international-phone-country-selector-dropdown{
    transform: translateY(-125%);
}

.spinnerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  /* carousel style start */
.CarouselRadioContain {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}
.CarouselRadioContain label{
    margin-bottom: 0px;
}
.form_group button.bg_green,.bg_green{
    background-color: green;
    color: #fff;
   width: 150px;    
}
.CarouselContain{
    border-top: 1px solid gray;
}
.CarouselCardsContain{
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    gap: 10px;
    overflow: auto;
}
.CarouselCard{
    min-width: 400px;
    max-width: 400px;
    border-radius: 5px;
    padding: 10px;
    padding-top: 35px;
    position: relative;
    background-color: #fff;
    border: 2px solid #dbdada;

}
.CountCloseContain{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.CountCloseContain span{
    font-size: 13px;
    padding: 5px;
    border-bottom-right-radius: 10px;
    background-color: #dbdada;
}
 .CountCloseContain button{
    border: none;
    font-size: 13px;
    padding: 5px;
    background-color: red;
    width: 35px;
    margin: 0;
}
  .CountCloseContain button img{
    width: 16px;
    filter: invert()
}
.ImojiContain{
    display: flex;
    align-items: center;
    justify-content: start;
    /* gap: 5px; */
}
.ImojiContain button{
    width: 35px;
}
.ImojiContain button img{
    width: 16px;
    filter: invert();
}
.CarouselText span{
    display: block;
    text-align: end;
    font-size: 10px;
}
.CrouselBtnContain input,.CarouselFormGroup textarea{
    border-radius: 5px;
    font-size: 13px;
}
.bordderL{
    border-left: 1px solid #e1dede;
}
.emoji-picker-container{
    top: 0;
    z-index: 101;
}
.emoji-picker-container button{
    background-color: transparent;
    box-shadow: none;
    color: #555;
}
.emoji-picker-container button:hover{
    background-color: #f5f5f5;
}
.emoji-picker-container button img,.message_icons .epr-emoji-img{
    filter: none;
}
.AddButtonGroup a:hover{
background-color: #0a58ca;
color: #fff;
}
.CharLimit{
    font-size: 10px;
    display: block;
    text-align: end;
}
/* carousel style end */



/* button groupings style start */
.ButtonGroupings h4{
    font-size: 16px;
    margin-bottom: 5px;
}
.ButtonGroupings ol li{
    font-size: 13px;
    color: #4c5258;
}
/* button grouping style end */