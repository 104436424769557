.Footer{
    background-color: #424141;
    padding: 1rem 0;
    width: calc(100% - 260px);
    z-index: 101;
    margin-left: auto;
    position: relative;
}
.sidebar-collapsed .Footer{
    width: calc(100% - 85px);
}


.Footer p{
    color: #fff;
    text-align: center;
    margin-bottom: 0px;
    /* text-transform: uppercase;
    letter-spacing: 1px; */
}

@media(max-width:992px){
    .Footer,.sidebar-collapsed .Footer{
        width: 100%;
    }
}