
ul{
    padding: 0;
}
#Chatbot{
    padding: 0px 0px 10px 0px;
}
.Chatbot_header{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 15px 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}
.Chatbot_header h5{
    margin: 0;
    text-wrap: nowrap;
}
.Chatbot_header h5 span{
    font-size: 18px;
    color: #00b7ff;
}
.Chatbot_leftBar{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}
.Chatbot_leftBar ul{
    padding: 0;
}
.Chatbot_leftBar ul li{
    display: flex;
    align-items: end;
    justify-content: center;
    margin-bottom: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f5f5f5;
}
.Chatbot_leftBar ul li:last-child{
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}
.Bot_group{
    width: 100%;
}.userinput_li_group.userinput_li_group_list{
    padding-bottom: 0;
        margin-bottom: 0px;
        border-bottom: 1px solid transparent;
}
.Bot_group.UserInput_box{
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 5px;
    position: relative;

}.Bot_group.UserInput_box input{
    width: initial;
    cursor: pointer;
    position: absolute;
    margin-left: 76px;
    margin-top: 5px;
    
}
.Bot_group label{
display: block;
margin-bottom: 5px;
font-size: 16px;
color: rgb(18 114 152);
}
.Bot_group select,.Bot_group input, .Bot_group textarea{
    width: 100%;
    border: 1px solid #bebebe;
    padding: 7px;
    border-radius: 5px;
    outline: none;
    font-size: 13px;
}
.Bot_group select option{
    font-size: 13px;
}
.Bot_group select option:hover{
    background-color: rgb(0, 183, 255);
    color: #fff;

}
.Bot_group select:focus,.Bot_group input:focus{
    outline: none;
}
.AddBtn{
    border: none;
    padding: 7px 0;
    width: 80px;
    border-radius: 5px;
    background-color: rgb(0, 183, 255);
    color: #fff;
    margin-left: 5px;
}
.AddBtn img{
    filter: invert();
    width: 21px;
}
.Bot_saveBtn{
    width: 100%;
    border: none;
    padding: 7px 0;
    border-radius: 5px;
    background-color: rgb(0, 183, 255);
    color: #fff;
    margin-left: 5px;
    margin-top: 10px;
}

.Chatboat_rightBar{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    min-height: 455px;
}
.Chatboat_rightBar h4{
    padding: 10px;
    background-color: #f5f5f5;
}
.Chatboat_rightBar h4 span{
    font-size: 18px;
}
.BotTable_contain{
    max-height: 380px;
    overflow: auto;
    scrollbar-width: none;
}
table.Bot_table{
    border-collapse: separate;
    border-spacing: 0;
    border: 0;
    width: 100%;
}
table.Bot_table  thead{
    background-color: #bebebe;
    position: sticky;
    top: 0;
    z-index: 11;
}
.Bot_table th{
    text-wrap: nowrap;
    background-color: rgb(0, 183, 255);
    color: #fff;
    border: 1px solid #f7f7f7;
    border-left: 0;
}
.Bot_table th:first-child,.Bot_table td:first-child{
    border-left: 1px solid #f7f7f7;
}
.Bot_table td{
    color: #555252;
    font-size: 13px;
    font-weight: 400;
    border-right: 1px solid #f7f7f7;
    border-bottom: 1px solid #f7f7f7;
}
.Bot_table td,.Bot_table th{
    text-align: center;
    padding: 10px;
}
/* .Bot_table td.Answer_td{
    text-align: left;
} */
.Bot_table td.Answer_td{
    letter-spacing: 1px;
}
.Bot_table td.Answer_td p{
    overflow: auto;
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    margin: 0;
    max-height: 40px;
}
.error{
    color: red;
    font-weight: 400;
    font-size: 12px;
    margin-left: 5px;
}
.ChatBotDeleteBtn{
    background-color: red;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.ChatBotDeleteBtn img{
    width: 20px;
    filter: invert();
}
.DeleteRow{
    width: 30px;
    height: 30px;
    background-color: red;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 5px;
    margin: auto;
}
.DeleteRow img{
    width: 16px;
    filter: invert();
}


.scrollable {
    max-height: 150px; /* Adjust the height as per your requirement */
    overflow-y: auto; /* Enables vertical scrolling */
    /* padding: 10px;    */
    /* border: 1px solid #ccc; */
  }
