/* .AmountRangeBar{
    padding: 10px 0;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.table_contain1 {
    overflow-x: auto;
    width: 100%;
    padding: 0 10px;
}

.AmountRangeBar {
    display: flex;
    align-items: end;
    margin-left: 11px;
    gap: 20px;
}

.AmountRangeBar .from-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.AmountRangeBar .from-group input {
    border: 1px solid #ccc;
    padding: 6px;
    outline: none;
    border-radius: 5px;
}

.AmountRangeBar button {
    padding: 7px;
    border: none;
    background-color: #343A40;
    color: #fff;
    border-radius: 5px;
}

@media(max-width: 552px) {
    .AmountRangeBar {
        display: block;
    }

    .AmountRangeBar button {
        margin-top: 20px;
    }

    .AmountRangeBar .from-group {
        margin-top: 10px;
    }
}
