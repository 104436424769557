.Mis_report_contian{ 
    background-color: #fff;
    border-radius: 10px;
}
.Head_title{ 
    text-align: center;
    padding: 10px 0;
    font-size: 22px;
    background-color: gray;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
}
.Mis_report_Card122{
    padding-top: 10px;
}
.Mis_report_Card122 .Date_contain{
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    align-items: end;
    justify-content: start;
    padding-bottom: 20px ;
    margin-left: 10px;
}
.Mis_report_Card122 .form_group221{
    margin-top: 10px;
}
.form_group221{
    margin-right: 20px;
}
.form_group221:last-child{
    margin-right: 0;
}
.form_group221 label{
    display: block;
    /* color: #d61616;; */
}
.form_group221 input{
    font-size: 14px;
    padding: 7px;
    border: 1px solid #bbbaba;
    border-radius: 5px;
}
.form_group221 button{
    border-radius: 5px;
    border: none;
    background-color: #343a40;
    font-size: 14px;
    /* width: 85px; */
    color: #fff;
    padding: 8px 14px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    display: flex;
    align-items: center;
}
.form_group221 button.export_btn{
    background-color: green;
}
.form_group221 button img{
    filter: invert();
    width: 16px;
    margin-right: 10px;
}
.table_contain{
    width: 100%;
    overflow-x: auto;
}
.Table{
    margin: 0;
}
.Table tr th,.Table tr td{
    border-top: none;
    border-left: none;
    border-right: 1px solid #bbbaba;
    border-bottom: 1px solid #bbbaba;
    text-align: center;
    padding: 7px;
}
.Table tr th:first-child,.Table tr td:first-child{
    border-left: 1px solid #bbbaba;
}
.Table  tr th{
    border-top: 1px solid #bbbaba;
    text-wrap: nowrap;
}
.Table tr th{
    background-color: #e1dede;
    font-size: 13px;
    vertical-align: middle;
    padding: 10px;
}
.Table tr td{
    font-size: 12px;
    font-weight: 400;
    background-color:#fff;
    vertical-align: middle;
}
.Table tfoot tr td{
    background-color:#fbf0ef;
}
.Table tr td input{
    width: 100%;
    border: 1px solid #cbc8c8;
    padding: 7px;
    border-radius: 5px;
}
.Table tr td button{
    border: none;
    /* background-color: #343a40; */
    background-color: #f90000;
    color: #fff;
    padding: 7px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    margin-top: 5px;
}
.Table td button.status_deactive,.Table td.status_deactive{
    color: #f90000;
}
.Table td button.status_active,.Table td.status_active{
    color: green;
}
.Table td button.download_icon,.Table td.download_icon button{
    background-color: #00acc1;
}
/* .Table td button:hover{
    background-color: #161616;

} */
.Table td button img{
    width: 16px;
    filter: invert();
}
.add_btn{
    border: none;
    background-color: #343a40;
    font-size: 14px;
    width: 85px;
    color: #fff;
    padding: 7px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    position: absolute;
    right: 7px;
    top: 7px;
}
.add_btn img{
    width: 18px;
    filter: invert();
    margin-right: 5px;
}
.dt-layout-full:has(){
    max-height: 400px;
    overflow: auto;
    padding: 0;
}
.row.dt-layout-table{
    margin: 0;  
}
.dt-info{
    font-weight: 400;
}

.tableDataCenterPosition{
    text-align: 'center'
}


.table_contain.tMisReport{
    height: 350px;
}
.DateRangeContain{
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
}
.DateRangeCalender{
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 101;
}
.react-calendar__month-view__weekdays__weekday abbr{
    color: #000;
}
.DateRangePikerButton {
    font-size: 13px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 26px;
}
.DateRangeReport{
    padding: 10px;
    display: flex;
    justify-content: start;
    align-items: end;
}
.DailyReportTable tbody tr td:last-child,.ColorBlueTable tr td:nth-child(4), 
.ColorBlueTable tr td:nth-child(5){
    color: #007bff;
}
.SearchDateRange{
    border: none;
    border-radius: 5px;
    background-color: #fff;
    padding: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 26px;
}
.SearchDateRange img{
    width: 16px;
}
.Head_title.MisReportTitle{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}
.Head_title.MisReportTitle .Session_report_SearchContain{
    position: static;
}
/* .MisReportTitle .Session_report_SearchContain,.MisReportTitle .Session_report_SearchContain .ExcelIconContain{
    position: static;
} */
.MisReportTitle .Session_report_SearchContain .ExcelIconContain{
    margin-left: 5px;
    margin-top: 3px;
}
.MisReportTitle .ExcelIconContain{
    background-color: #fff;
    padding: 0 5px;
    border: none;
    border-radius: 5px;
    position: static;
}
.ExcelIcon{
    width: 16px;
}

@media(max-width:365px){
    .Mis_report_Card122 .form_group221:has(> input),.Mis_report_Card122 .form_group221 input{
        width: 100%;
    }
}

/* Marquee container styling */
.marquee-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #f1f1f1; /* Adjust background color as needed */
    /* padding: 10px 0; */
    color: red;
  }
  
  .marquee-text {
    white-space: nowrap;
    display: inline-block;
    padding-left: 100%; /* Start off-screen */
    animation: marquee 15s linear infinite;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  