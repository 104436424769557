.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.not-found-title {
    font-size: 48px;
    color: #333;
}

.not-found-message {
    font-size: 18px;
    color: #666;
    margin: 20px 0;
}

.not-found-button {
    padding: 12px 24px;
    font-size: 16px;
    color: #fff;
    background-color: #343a40;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.not-found-button:hover {
    background-color: #343a40bb;
}