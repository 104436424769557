
@import url('https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root {
    --praimary-color: #cc0001;
    --secondary-color:#0E1824;
    --praimary-color-grb: 247, 0, 0;
    --white-color: #fff;
    
}
  ::selection {
    color: #fff;
    background: #000
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    scrollbar-color: var(--praimary-color) var(--black-color2);
}
h4{
    font-size: 15px;
    margin: 0;
}

ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
a{
    text-decoration: none;
    color: #000;
}
p{
    margin: 0;
}
button{
    outline: none;
}
body{
    background-color: #f0f2f5 !important;
}
.Contain{
    position: relative;
    min-height: calc(100vh - 49px);
    margin-left: auto;
    width: calc(100% - 260px);
    transition: .4s;
}

.sidebar-collapsed .Contain{
    width: calc(100% - 85px);
}
 /* .Sidebar:hover ~ .Contain {
    width: calc(100% - 260px) ;
} */
.Dash_contain{
    padding: 20px ;
    
}
@media(max-width:992px){
    .Contain,.sidebar-collapsed .Contain{
        width: 100%;
    }
}