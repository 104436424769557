.WB_customize_contain {
    background-color: #fff;
    border-radius: 10px;
}

.Head_title {
    text-align: center;
    padding: 10px 0;
    font-size: 22px;
    background-color: gray;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* margin-bottom: 20px; */
}

.Customize_card {
    padding: 20px;
}

.Customize_card>p {
    font-size: 16px;
    margin-bottom: 10px;
}

.custm_docs {
    border: 1px solid grey;
    padding: 20px;
    text-align: center;
    background-color: #dfdddd;
    background-color: #ededed;
    border-radius: 10px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 10px;
}

.custm_docs p {
    margin-bottom: 10px;
}

.custm_docs h4 {
    font-size: 18px;
    font-weight: 600;
    color: rgb(14, 13, 78);
    margin-bottom: 10px;
}

.custm_docs button {
    border: none;
    background-color: #343a40;
    color: #fff;
    padding: 7px 15px;
    border-radius: 5px;
}

.form_group11 {
    margin-top: 10px;
}

.form_group11 label,
label {
    font-weight: 500;
    margin-bottom: 5px;
}

.form_group11 input,
.form_group11 select {
    width: 100%;
    border: 1px solid #b7b5b5;
    font-weight: 400;
    padding: 10px;
}

.form_group11 input.p_7 {
    padding: 7px;
}

.form_group11 select::after,
.form_group11 select::before {
    background: none;
    content: '';
}

.form_group11 select.h_85 {
    height: 85px;
    -webkit-appearance: none;
    appearance: none;
    overflow: scroll;
}

option {
    font-weight: 400;
}

.form_group11 textarea {
    border: 1px solid #b7b5b5;
    padding: 10px;
    width: 100%;
}

.form_group11 textarea:focus-visible,
.form_group11 select:focus-visible {
    outline: none;
}

.form_group11 button {
    border: none;
    background-color: #343a40;
    color: #fff;
    padding: 7px 0;
    width: 130px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    margin-top: 5px;
}

.form_group11 button:hover {
    background-color: #161616;

}

.custom_select_container {
    width: 100%;
}

.custom_select {
    height: 86px;
    border-radius: 0;
}

.custom_select option {
    padding: 3px 8px;
    background: #fff;
    color: #000;
    font-weight: 400;
}

.custom_select option:hover {
    background: #f0f0f0;
}

.custom_select option:checked,
.custom_select option[selected] {
    background: #007bff;
    color: #fff;
}

.List_card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.List_card span {
    margin-top: 10px;
}

.form_group11 button {
    border: none;
    background-color: #343a40;
    color: #fff;
    padding: 7px 0;
    width: 130px;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .4s;
    margin-top: 5px;
}

.form_group11 button:hover {
    background-color: #161616;

}

.country_code_box.col-lg-2 {
    margin-top: 23px;
}

.country_code_box h6 {
    font-size: 16px;
}

.country_code_input.form_group11 {
    margin-top: 0;
}

.country_code_input.form_group11 input {
    padding: 3px 9px;
    height: 41px;
    margin-top: 6px;
    border: 1px solid #b7b5b5;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-left: 0;
    width: 100%;
}

.country_code_input.form_group11 .react-international-phone-country-selector-button {
    padding: 0;
    margin: 0;
    margin-top: 6px;
    border: 1px solid #b7b5b5;
    border-right: 0;
    border-radius: 0;
    height: 41px;
    width: 70px;
}

.add_btn6.add_country_btn {
    width: 120px;
}


.modal_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1050;
    border-radius: 10px;

}

.modal_header_new {
    background-color: grey;
    color: #fff;
    text-align: center;
    padding: 8px;
    font-size: 22px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

}

.btn_m_save {
    display: flex;
    justify-content: right;
    margin-right: 20px;
    gap: 10px;
    margin-bottom: 13px;
}

.country_code_box.col-lg-6 {
    margin-top: 25px;
}

.btn_m_save .btn.btn-primary.add_country {
    background-color: #00acc1;
    border: 1px solid #00acc1;
}

.btn_m_save .btn.btn-primary.add_country:hover {
    background-color: #077f8f;
    border: 1px solid #077f8f;
}

.input_h_s::-webkit-outer-spin-button,
.input_h_s::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input_h_s {
    -moz-appearance: textfield; 
}

@media(max-width: 768px) {
    .modal_container {
        width: 80%;
    }
}